    /*固定右側icon*/

    .icon_Community a.js-btn-icon_Community {
        text-align: center;
        width: 50px;
        border-radius: 100px;
        position: absolute;
        // bottom: 0;
        bottom: 62px;
        right: 0;
        padding: 0;
        cursor: pointer;
        color: #fff;
        background: #000;
        font-size: 22px;
        box-shadow: 0 3px 5px rgba(0, 0, 0, 0.5);
        height: 50px;
        pointer-events: auto;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
            height: 23px;
            width: auto;
            fill: #fff;
        }
    }

    .js-btn-icon_Community:hover,
    .js-btn-icon_Community:focus {
        color: #fff;
        background-color: #0069d9;
        border-color: #0062cc;
    }

    .js-btn-icon_Community~.js-icon_Community.active {
        position: relative;
        bottom: 64px;
    }

    .js-icon_Community .icon_li {
        position: relative;
        right: -100px;

        .icon_li_img {
            max-width: 50px;
            width: 100%;
            height: auto;
            display: block;
            position: absolute;
            right: 0;
            z-index: 1;
        }
    }

    .js-icon_Community.active .icon_li {
        right: 0;
        pointer-events: auto;
    }

    .js-icon_Community .icon_li:nth-child(1) {
        transition: all 0.1s ease-out;
        -moz-transition: all 0.1s ease-out;
        -o-transition: all 0.1s ease-out;
        -webkit-transition: all 0.1s ease-out;
    }

    .js-icon_Community .icon_li:nth-child(2) {
        transition: all 0.3s ease-out;
        -moz-transition: all 0.3s ease-out;
        -o-transition: all 0.3s ease-out;
        -webkit-transition: all 0.3s ease-out;
    }

    .js-icon_Community .icon_li:nth-child(3) {
        transition: all 0.5s ease-out;
        -moz-transition: all 0.5s ease-out;
        -o-transition: all 0.5s ease-out;
        -webkit-transition: all 0.5s ease-out;
    }

    .js-icon_Community .icon_li:nth-child(4) {
        transition: all 0.7s ease-out;
        -moz-transition: all 0.7s ease-out;
        -o-transition: all 0.7s ease-out;
        -webkit-transition: all 0.7s ease-out;
    }

    .js-icon_Community .icon_li:nth-child(5) {
        transition: all 0.9s ease-out;
        -moz-transition: all 0.9s ease-out;
        -o-transition: all 0.9s ease-out;
        -webkit-transition: all 0.9s ease-out;
    }

    .js-icon_Community .icon_li:nth-child(6) {
        transition: all 1.1s ease-out;
        -moz-transition: all 1.1s ease-out;
        -o-transition: all 1.1s ease-out;
        -webkit-transition: all 1.1s ease-out;
    }

    .js-icon_Community .icon_li:nth-child(7) {
        transition: all 1.3s ease-out;
        -moz-transition: all 1.3s ease-out;
        -o-transition: all 1.3s ease-out;
        -webkit-transition: all 1.3s ease-out;
    }

    .js-icon_Community .icon_li:nth-child(8) {
        transition: all 1.5s ease-out;
        -moz-transition: all 1.5s ease-out;
        -o-transition: all 1.5s ease-out;
        -webkit-transition: all 1.5s ease-out;
    }

    .js-icon_Community .icon_li:nth-child(9) {
        transition: all 1.7s ease-out;
        -moz-transition: all 1.7s ease-out;
        -o-transition: all 1.7s ease-out;
        -webkit-transition: all 1.7s ease-out;
    }

    .icon_Community {
        position: fixed;
        right: 20px;
        // bottom: 0;
        bottom: 26px;
        z-index: 1000;
        pointer-events: none;
    }

    .icon_Community_ul {
        list-style: none;
        padding: 0;
        flex-direction: column;
        flex-flow: column-reverse;
        align-items: flex-end;
        display: flex;
        margin-bottom: 50px;
    }

    .icon_img {
        background: #000;
        border: none;
        overflow: hidden;
        text-align: center;
        margin-bottom: 6px;
        width: 50px;
        text-decoration: none;
        border-radius: 100px;
        color: #fff;
        transition: all 0.3s ease;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .icon_img.active {
        width: 175px;
    }

    .icon_img.active .icon_name {
        text-indent: 0;
        opacity: 1;
    }

    .icon_img:hover {
        text-decoration: none;
        width: 175px;
    }

    .icon_img:before {
        opacity: 0;
    }

    .icon_img .icon {
        color: #fff;
        font-size: 22px;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1;

        &.svgIcon {
            fill: #fff;
            position: absolute;
            right: 0;
            z-index: 1;
            width: 50px;
            height: 100%;
            padding: 13px;

            svg {
                height: 23px;
                width: auto;
            }
        }
    }

    .icon_img:hover .icon {
        text-decoration: none;
    }

    .icon_img:hover .icon_name {
        opacity: 1;
        text-indent: 0;
    }

    .icon_name {
        position: relative;
        line-height: 50px;
        padding-right: 50px;
        padding-left: 15px;
        transition: all 0.3s ease;
        opacity: 0;
        text-decoration: none;
        color: #fff;
        white-space: nowrap;
        border-radius: 100px;
        overflow: hidden;
        text-indent: -100px;
        font-family: Arial, "Microsoft JhengHei", sans-serif;
    }

    /*彈跳視窗*/

    .js_lightbox_wechat {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        z-index: 9999;
        display: none;
    }

    .content-wechat {
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 375px;
        width: calc(100% - 15px);
        background: #fff;
        padding: 75px 30px 30px;
        text-align: center;
        box-shadow: 0 3px 5px rgba(0, 0, 0, 0.5);
    }

    .js_lightbox_wechat .js-close {
        position: absolute;
        top: 0;
        right: 0;
        width: 50px;
        line-height: 50px;
        text-align: center;
        cursor: pointer;

        svg {
            height: 20px;
            width: 20px;
            fill: #fff;
        }
    }

    .title-wechat {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        line-height: 50px;
        background: #08b506;
        color: #fff;
        font-size: 26px;
        font-family: Arial, sans-serif;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
            height: 20px;
            width: 20px;
            fill: #fff;
            margin-right: 10px;
        }
    }

    .id-wechat {
        font-family: Arial, sans-serif;
        font-size: 21px;
        margin-bottom: 0;
        margin-top: 10px;
    }

    .content-wechat img {
        height: 130px;
        width: auto;
    }

    /*icon 社群顏色*/

    .bg_transparent {
        background: transparent;
    }

    .bg_iconFacebook {
        background: #4064ad;
    }

    .bg_iconLine {
        background: #02be01;
    }

    .bg_iconInstagram {
        /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#fcb045+0,e6233c+22,ab3586+71,5719a8+100 */
        background: rgb(252, 176, 69);
        /* Old browsers */
        background: -moz-linear-gradient(45deg, rgba(252, 176, 69, 1) 0%, rgba(230, 35, 60, 1) 22%, rgba(171, 53, 134, 1) 71%, rgba(87, 25, 168, 1) 100%);
        /* FF3.6-15 */
        background: -webkit-linear-gradient(45deg, rgba(252, 176, 69, 1) 0%, rgba(230, 35, 60, 1) 22%, rgba(171, 53, 134, 1) 71%, rgba(87, 25, 168, 1) 100%);
        /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(45deg, rgba(252, 176, 69, 1) 0%, rgba(230, 35, 60, 1) 22%, rgba(171, 53, 134, 1) 71%, rgba(87, 25, 168, 1) 100%);
        /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    }

    .bg_iconMessenger {
        background: #0084ff;
    }

    .bg_iconYoutube {
        background: #e02a20;
    }

    .bg_iconPhone {
        background: #000;
    }

    .bg_iconMail {
        background: #000;
    }

    .bg_iconWechat {
        background: #09b507;
    }

    .bg_iconWhatsapp {
        background: #20B038;
    }